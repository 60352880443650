/* GLOBAL STYLES */
/*****************************/
#root {
  width: 100vw;
}

body {
  overflow-x: hidden;
  background-color: snow;
}

h2, h3 {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

h2, h3, h4, p {
  text-align: center;
  color: darkslategray;
  font-family: 'El Messiri';
}

h1 {
  font-family: 'El Messiri';
  text-align: center;
  text-transform: uppercase;
  color: rgb(52, 90, 90);
  font-size: 7vh;
  text-shadow: whitesmoke 1px 2px 1px;
}

h2 {
  font-size: 3rem;
  text-transform: capitalize;
}

h3 {
  font-size: 1.8rem;
}

h4 {
  font-size: 1.3rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

span, p {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* font-size: 20px; */
}

.p-grid {
  margin-right: 0em !important;
  margin-left: 0em !important;
}

/* body .p-button:active {
  background-color: transparent !important;
}

body .p-button:hover {
  background-color: green !important;
} */

.home-component {
  /* height: 100vh; */
  /* margin-bottom: 1rem; */
  /* margin-bottom: 2.5rem; */
}

/*****************************/
/* NAVIGATON STYLES */
/*****************************/

.navigation > div {
  margin-top: 2rem;
  padding: 0em;
}

.navig-menu div:first-child {
  padding-left: 0em;
}

.navig-menu div:last-child {
  padding-top: 0em;
  padding-right: 0em;
}

/* .navig-menu :nth-child(2) {
  padding-bottom: 0em;
}

.navig-menu :nth-child(3) {
  padding-bottom: 0em;
} */

.pmenubar.pcomponent {
  width: fit-content;
}

.p-menuitem-text {
  font-size: large;
  color: darkslategray !important;
}

/* .logo-wrapper {
  padding-left: 0em;
} */

.logo-header {
  max-width: 140px;
  min-width: 130px;
  width: 10%;
  padding-bottom: 0em;
  padding-left: 0.3em;
}

.es-icon {
  background: url('../src/resources/spain-flag-icon.png');
  min-width: 16px;
  background-repeat: no-repeat;
  background-size: contain;
}

.en-icon {
  background: url('../src/resources/usa-flag-icon.png');
  min-width: 16px;
  background-repeat: no-repeat;
  background-size: contain;
}

div.p-menubar.p-component {
  background-color: transparent;
  border: none;
  max-width: 20rem;
  /* margin: auto; */
  display: flex;
  justify-content: flex-end;
}

/*****************************/
/* TOGGLELANGUAGE STYLES */
/*****************************/

.toggleLangButton.p-button {
  background-color: rgb(47, 47, 97) !important;
  border: 2px solid teal !important;
}

.toggleLangButton.p-button:hover {
  background-color: rgb(10, 70, 150) !important;
  border: 2px solid lightseagreen !important;
  
}

.toggleLangButton > span {
  font-size: 14px;
  font-family: 'Alegreya';
  letter-spacing: 1px;
}

/*****************************/
/* HERO STYLES */
/*****************************/

#hero {
  /* width: 100vw; */
  overflow-x: hidden;
  /* padding-top: 1rem; */
  /* margin-bottom: 1rem; */
}

#hero > div {
  padding-left: 0em;
  padding-right: 0em;
}

.hero-content {
  height: 80vh;
  overflow-x: hidden;
  padding-top: 0.3rem;
}

.hero-wrapper {
  height: 100%;
}

.hero-wrapper div:nth-child(1) {
  max-height: 10%;
}

/* .hero-wrapper div:nth-child(2) {
  flex-grow: 2;
  height: 40%;
  max-width: 85%;
} */

.hero-wrapper div:nth-child(3) {
  max-height: 20%;
}

.hero-text-wrapper {
  height: 100%;
  background-color: rgba(255, 200, 135, 0.70);
  border-radius: 10px;
  border: 1px solid aliceblue;
}

.react-parallax {
  width: 100%;
}


/* .p-menubar-custom {
  width: 100%;
  background-color: transparent;
}

.p-menubar-custom img {
  float: left;
  align-self: center
} */

.hero-appointment {
  /* background-color: rgb(235, 234, 231); */
  background: teal;
  border: 2px solid rgb(28, 49, 49);
  border-radius: 6px;
  /* color: rgb(28, 49, 49); */
  color: snow;
  cursor: pointer;
  font-size: 26px;
  font-family: 'Vollkorn';
  font-weight: bold;
  margin: 10px;
  padding: 14px 20px;
  box-shadow: 0 2px 4px darkgray;
  letter-spacing: 1px;
  text-shadow: black 1px 1px 1px;
}

.hero-appointment:hover {
  background: snow;
  /* background: rgb(235, 234, 231); */
  /* background: teal; */
  border: 2px solid black;
  color: teal;
  /* color: rgb(28, 49, 49); */
  /* color: snow;  */
  /* text-shadow: none; */
}

.hero-appointment:active {
  background-color: rgb(235, 234, 231);
  /* background: rgb(0, 139, 139); */
  /* background: rgb(0, 138, 138); */
  box-shadow: 1px 1px 1px black;
}

/*****************************/
/* SERVICES STYLES */
/*****************************/

#services {
  padding-top: 2rem;
  /* margin-bottom: 2.5rem; */
}

.carousel {
  height: 100%;
}

.p-carousel-container {
  height: 90%;
  width: 95vw;
}

.p-carousel-item {
  height: 60%;
  padding: 10px;
}

.p-card-header {
  height: 100%;
}

.p-card-body {
  height: 8rem;
  /* Only when the card has no other content than the title */
  display: flex;
  align-items: center;
  justify-content: center;
  /* Only when the card has no other content than the title */
}

.p-card-title {
  color: darkslategray !important;
  font-size: 28px !important;
  font-family: 'Vollkorn';
  letter-spacing: 1px;
  text-transform: capitalize;
  
}

.p-carousel-item img {
  height: auto;
}

.service-card > .p-card-body{
  text-align: center;
  background-color: azure;
}

/*****************************/
/* ABOUT STYLES*/
/*****************************/

#about {
  /* height: 80vh; */
  height: auto;
  margin: 8px;
  margin-bottom: 0px;
  /* background-color: ghostwhite; */
}

.about-container {
  min-height: 70vh;
  padding-top: 2rem;
}

.about-panel {
  height: 100% ;
  margin: 5px;
}

.panel-left {
  background-image: url("https://ik.imagekit.io/hakxelimgs/shutterstock_559544047_h8W0LjPWq.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 70vh;
}

.panel-right {
  border-style: outset;
  background-color: ghostwhite;
  /* border-right-color: teal;
  border-bottom-color: teal; */
  height: auto;
}

.panel-right div.p-grid {
  height: 100%;
}

.panel-right h2 {
  margin-top: 0px;
  margin-bottom: 1.5rem; 
}

.panel-right p {
  margin: auto;
  text-align: left;
}

/*****************************/
/* SCHEDULE STYLES */
/*****************************/

#schedule {
  /* height: 80vh; */
  height: auto;
  min-height: 85vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: ghostwhite; */
  margin-bottom: -2.3rem;
}

.schedule-wrapper {
  height: 80%;
  width: 100%;
}

.schedule-wrapper > div.p-col-12.p-md-10 {
  /* padding: 5em; */
  height: auto;
}

.schedule-info {
  width: auto;
  height: 60vh;
  background-image: url('https://ik.imagekit.io/hakxelimgs/shutterstock_636154916_Xyr4Xp3Hv.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: right;
}

.info-wrapper {
  background-color: rgba(252, 244, 233, 0.89);
  height: auto;
  width: 80%;
  padding: 1rem;
  border: 3px solid aliceblue;
  border-radius: 5px;
}

.hours-wrapper {
  margin-top: 1.5rem;
}

.hours-wrapper h4 {
  margin-bottom: 1rem;
}

.schedule-hours {
  text-align: center;
}

.schedule-hours > p {
  margin: 8px 0px 16px 0px;
}

.schedule-hours > span {
  border-bottom: 1px solid darkgoldenrod;
  color: darkslategray;
}

/*****************************/
/* CONTACT STYLES */
/*****************************/

.contact-header {
  width: 100%;
  height: auto;
  min-height: 80vh;
  background-image: url('https://ik.imagekit.io/hakxelimgs/shutterstock_489410641_K1a76WU6_.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.contact-header > h2 {
  background-color: rgba(240, 248, 255, 0.719);
  border: 1px solid goldenrod;
  border-radius: 5px;
  padding-top: 5px !important;
}

.contact-form {
  margin-top: 2rem;
}

.form-field {
  margin: 15px;
}

.submit-button {
  padding-right: 0px;
  padding-left: 0px;
}

.error-message {
  color: red;
  margin-top: 2px;
}

/*****************************/
/* LOCATION STYLES */
/*****************************/
#location {
  margin-bottom: 8px;
}

address {
  font-size: larger;
  font-weight: 800;
  color: darkslategray;
  margin: 16px;
  text-align: center;
}

.map-wrapper {
  padding: 0px;
}

/*****************************/
/* APPOINTMENTS STYLES */
/*****************************/

#appointments {
  min-height: 100vh;
  background-color: #fffafa;
}

.booking-wrapper {
  margin-bottom: 8px;
}

/* IFRAME CONTENT STYLE */
.subHeaderWrap > div::after {
  display: none;
}

#subHeader {
  background-color: blue !important;
}

/*****************************/
/* LOADER STYLES */
/*****************************/

.loader {
  height: calc(100vh - 152px);
  margin-bottom: 16px;
}

/*****************************/
/* FOOTER STYLES */
/*****************************/

.footer-wrapper {
  height: auto;
}

.footer-wrapper > div {
  padding: 0px;
}

.footer-content {
  padding-top: 25px;
  padding-bottom: 5px;
  background-color: black;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  /* padding: 10px; */
}

.footer-content p, .footer-content i {
  color: snow;
}

.footer-content > .p-col-12 {
  padding-left: 20px;
  padding-right: 20px;
}

.logo-footer {
  max-width: 140px;
  min-width: 130px;
  width: 10%;
}

/*****************************/
/* MEDIA QUERIES */
/*****************************/

@media screen and (min-width: 1350px) and (max-width: 2000px) and (orientation: portrait) {
  #about {
    height: 36vw;
    margin-top: 1rem;
  }

  .about-container {
    max-height: 40vw;
  }

  .about-panel {
    height: 30vw;
    margin: 15px;
  }

  #schedule {
    min-height: 40vw;
  }

  .schedule-wrapper > div.p-col-12.p-md-10 {
    height: 35vw;
  }

}

@media screen and (max-width: 767px) {

  h2 {
    font-size: 2.8rem;
  }
  
  h3 {
    font-size: 1.5rem;
  }
  
  h4 {
    font-size: 1.1rem;
  }

  .navig-menu > div > div.p-grid {
    justify-content: center;
  }

  .navig-menu div:first-child {
    margin-bottom: 0.3rem;
  }

  .navig-menu div:last-child {
    padding-bottom: 0em;
    padding-left:  0em;
  }

  .logo-link {
    display: flex;
    justify-content: center;
    margin-right: -10px;
  }

  .schedule-info {
    height: 70vh;
  }

  .footer-item {
    align-content: center;
    justify-content: center;
    padding-top: 10px;
  }

  .footer-content :first-child {
    margin-right: -2px;
  }
}

@media screen and (max-width: 376px) {
  h1 {
    font-size: 6vh;
  }

  h2 {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
  }
  
  h3 {
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
  }
  
  h4 {
    font-size: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  
  p {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  #schedule {
    height: 100vh;
  }

  .schedule-info {
    height: 78vh;
  }
}

@media screen and (max-width: 330px) {

  #schedule {
    height: 130vh;
  }

  .schedule-info {
    height: 105vh;
  }
}

@media screen and (max-height: 476px) and (orientation: landscape) {
  #about {
    height: 135vh;
  }

  #schedule {
    height: 170vh;
  }

  .schedule-info {
    height: 130vh;
  }
}

@media screen and (max-height: 376px) and (orientation: landscape) {
  #about {
    height: 165vh;
  }

  /* #schedule {
    height: 170vh;
  }

  .schedule-info {
    height: 130vh;
  } */
}

@media screen and (max-height: 326px) and (orientation: landscape) {
  #about {
    height: 175vh;
  }

  #schedule {
    height: 210vh;
  }

  .schedule-info {
    height: 150vh;
  }
}

@media screen and (min-width: 768px) and (max-width: 825px) and (orientation: landscape) {
  .panel-left {
    width: 100%;
  }

  .panel-right {
    width: 90%;
  }
}

@media screen and (min-width: 800px) and (max-width: 850px) and (max-height: 400px) and (orientation: landscape) {
  #about {
    height: 135vh;
  }

  #schedule {
    height: 190vh;
  }
}

@media only screen and (min-width: 400px) and (max-width: 500px) and (orientation: landscape) and (max-height: 330px) {
  #about {
    height: 195vh;
  }

  #schedule {
    height: 210vh;
  }

  .schedule-info {
    height: 160vh;
  }
}

/* @media screen and (min-width: 1330px) and (min-height: 1000px) and (orientation: landscape {
  #about {
    height: 135vh;
    background-color: rebeccapurple;
  }

  #schedule {
    height: 170vh;
  }

  .schedule-info {
    height: 130vh;
  }
} */